import { CTA } from '@/components/Shared/CTA/CallToAction';
import { ICta } from '@/utils/interfaces/components';
import { CtaContainer } from './CTA/CtaContainer';
import { Container } from './Layout/Container';
import { Cutout } from '@/components/Shared/Icons/BrandBackgrounds/NotFound/Cutout';
import { Footer } from "@/components/Shared/Layout/Footer/Footer";

interface IProps {}

const blok: ICta = {
	_uid: '8c0a1e73-a0f4-4c83-a415-84c4d1a46f5a',
	icon: true,
	link: {
		id: '048a11d2-8d4e-4ed5-aef5-5f2afb2fdcd7',
		url: '/',
		linktype: 'url',
		fieldtype: 'multilink',
		cached_url: '/',
	},
	size: 'medium',
	label: 'Take me home',
	target: false,
	variant: 'secondary',
	component: 'cta',
	_editable:
		'<!--#storyblok#{"name": "cta", "space": "154592", "uid": "8c0a1e73-a0f4-4c83-a415-84c4d1a46f5a", "id": "128281840"}-->',
};

export const PageNotFound: React.FC<IProps> = () => {
	return (
		<>
            <section className="relative flex items-center bg-green-dark py-14 min-h-[532px] lg:py-50 lg:min-h-[624px]">
                <Cutout className="absolute bottom-0 right-0 max-h-full h-2/3 md:right-auto md:left-0 md:h-auto md:w-2/3" />
                <Container align="center">
                    <h1 className="text-white">Sorry,</h1>
                    <span className="block mt-4 text-lg text-white">
					The page that you are looking for doesn&apos;t exist…
				</span>
                    <div className="mx-auto mt-6 text-center">
                        <CtaContainer>
                            <CTA blok={blok} />
                        </CtaContainer>
                    </div>
                </Container>
            </section>
            <Footer/>
        </>
	);
};
