interface IProps {
    className: string;
}

export const Cutout: React.FC<IProps> = ({className}) => {
    return (
        <svg viewBox="0 0 1091 624"
             className={className}
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.3">
                <path
                    d="M135.14 647.826C141.39 656.284 142.921 662.831 141.826 668.898C141.044 672.764 139.743 676.253 137.965 679.254C135.968 682.515 135.032 686.821 135.272 691.648C135.781 699.914 132.924 703.168 126.143 701.953C123.928 701.419 121.676 700.541 119.443 699.34C118.018 698.596 116.616 697.635 115.274 696.483C112.528 694.164 109.611 692.442 106.703 691.42C103.794 690.398 100.954 690.098 98.3577 690.539C91.6631 691.353 85.7964 687.099 81.2631 678.342C79.5745 675.29 78.3373 672.03 77.6367 668.786C76.4677 661.852 73.8374 655.377 70.7671 648.69C68.1554 643.289 66.5983 637.582 66.2657 632.191C65.933 626.8 66.8376 621.931 68.8808 618.117C70.1193 615.365 72.1669 613.468 74.7606 612.669C77.3544 611.869 80.3758 612.205 83.4371 613.631C89.1674 615.869 94.8385 619.919 99.838 625.344C106.87 633.178 114.401 637.013 122.266 639.448C127.51 640.96 132.425 644.22 135.14 647.826Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M103.911 612.561C106.264 614.707 108.726 616.65 111.166 618.549C118.099 623.88 125.451 627.138 132.436 627.974C133.732 628.132 134.959 628.344 136.233 628.531C143.268 629.73 150.091 633.334 154.904 638.581C164.222 649.269 167.585 658.991 167.048 669.035C166.573 675.866 165.077 682.114 162.623 687.51C160.665 692.009 158.901 696.462 157.504 700.946C156.748 702.358 156.09 703.869 155.535 705.468C154.884 707.615 153.929 709.506 152.702 711.079C148.821 715.833 143.779 719.01 137.937 720.385C135.143 721.145 132.054 721.13 128.839 720.341C126.728 719.893 124.568 719.017 122.444 717.747C119.528 716.001 116.548 714.27 113.502 712.482C107.716 709.05 101.85 706.438 96.0413 704.708C92.3446 704.474 88.4027 702.915 84.6486 700.204C80.8945 697.493 77.4735 693.734 74.7612 689.34C72.5847 686.223 70.7229 682.844 69.2471 679.333C64.4269 671.52 58.2133 663.342 51.9683 653.51C51.0882 652.128 50.1619 650.568 49.329 649.07C41.0343 633.833 37.4767 616.795 43.6761 603.775C45.1608 600.411 47.4249 597.843 50.2872 596.277C53.1495 594.711 56.5302 594.191 60.1581 594.759C63.8476 595.379 67.6288 596.614 71.4036 598.431C74.1497 599.677 76.8678 601.12 79.666 602.578C82.285 604.014 84.9316 604.868 87.4609 605.092C92.8658 605.691 98.5779 608.285 103.911 612.561V612.561Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M107.857 599.702C111.058 602.185 114.306 604.553 117.533 606.949C126.689 613.738 136.379 617.388 145.289 617.404C146.887 617.444 148.53 617.57 150.144 617.638C158.935 618.433 167.694 622.405 174.692 629.27C187.006 642.231 192.298 655.102 192.306 669.1C192.185 678.922 190.507 687.967 187.354 695.78C184.843 702.257 182.204 708.469 179.748 714.183C178.353 715.774 177.053 717.486 175.854 719.315C174.373 721.79 172.537 723.862 170.396 725.473C163.966 730.168 157.361 734.512 149.761 738.854C146.447 740.885 142.523 741.754 138.27 741.399C135.481 741.246 132.558 740.458 129.634 739.073C125.535 737.107 121.45 734.863 117.401 732.354C109.545 727.41 101.62 722.939 93.6634 718.963C89.1914 717.888 84.6203 715.691 80.2318 712.506C75.8433 709.321 71.7301 705.217 68.1455 700.445C65.4519 697.17 62.9616 693.65 60.7174 689.943C52.2366 681.271 42.4424 671.383 33.0262 658.397C31.6441 656.482 30.3038 654.509 29.0129 652.492C16.5638 632.434 9.70861 607.819 18.3243 589.466C23.3056 578.83 31.8347 575.276 42.7651 577.265C48.1972 578.499 53.7191 580.517 59.219 583.277C63.3523 585.188 67.4466 587.504 71.5423 589.618C75.5141 591.813 79.5254 592.71 83.1208 592.21C90.6174 590.934 99.0667 593.982 107.857 599.702Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M111.864 586.957C115.884 589.792 119.946 592.57 123.954 595.429C135.35 603.69 147.382 607.733 158.19 606.931C160.138 606.821 162.105 606.787 164.093 606.828C174.649 607.212 185.341 611.57 194.478 620.058C209.844 635.26 216.985 651.266 217.553 669.265C218.002 681.314 215.83 693.409 212.006 704.04C209.041 712.193 205.671 719.993 201.91 727.409C199.859 729.165 197.932 731.133 196.093 733.129C193.781 735.887 191.073 738.118 188.034 739.767C178.685 744.697 169.834 750.532 161.536 757.237C157.706 760.509 152.949 762.27 147.645 762.378C144.193 762.49 140.527 761.778 136.819 760.277C131.614 758.124 126.416 755.387 121.296 752.105C111.044 745.607 101.037 738.929 91.3213 733.074C80.8605 729.246 70.4834 721.681 61.591 711.4C58.3586 707.927 55.2436 704.265 52.2651 700.436C40.1144 690.892 26.7483 679.291 14.1091 663.124C12.2451 660.741 10.4503 658.28 8.73117 655.749C-7.89169 630.882 -18.0367 598.685 -7.00718 575.018C-0.493516 560.978 10.8613 556.612 25.3851 559.619C32.3463 561.048 39.6817 564.526 47.0665 567.961C52.5603 570.533 58.0175 573.675 63.4968 576.493C68.8416 579.458 74.2306 580.389 78.8512 579.146C88.6579 576.386 99.9511 579.754 111.864 586.957Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M115.84 574.167C120.669 577.359 125.547 580.526 130.358 583.911C144.019 593.685 158.422 598.133 171.131 596.502C173.428 596.255 175.771 596.095 178.091 596.075C190.409 596.068 203.018 600.783 214.312 610.903C232.702 628.36 241.726 647.521 242.865 669.497C243.686 684.282 241.255 699.272 236.736 712.415C233.26 722.512 229.057 731.982 224.161 740.746C221.459 742.673 218.912 744.869 216.411 747.059C213.266 750.102 209.685 752.504 205.745 754.214C193.714 759.206 182.838 766.446 173.378 775.76C169.035 780.257 163.451 782.91 157.096 783.494C152.966 783.931 148.523 783.316 143.992 781.681C137.684 779.359 131.359 776.123 125.162 772.046C112.767 764.057 100.666 755.365 88.9772 747.38C77.1519 741.929 65.557 733.448 55.0613 722.574C51.0882 718.665 47.0634 714.635 43.8205 711.14C28.0257 700.73 11.0603 687.411 -4.73735 668.139C-7.05081 665.311 -9.30171 662.365 -11.4899 659.3C-32.3006 629.575 -45.7057 589.853 -32.287 560.848C-24.2314 543.398 -10.0159 538.33 8.08248 542.274C16.7498 544.168 25.8559 548.666 34.9747 552.938C41.8387 556.166 48.6572 560.153 55.4953 563.651C62.3334 567.15 68.9344 568.49 74.6264 566.364C86.6889 561.836 100.827 565.524 115.84 574.167Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M119.814 561.369C125.453 564.917 131.106 568.421 136.752 572.37C152.654 583.589 169.381 588.4 183.946 585.943C186.588 585.538 189.261 585.275 191.966 585.156C206.046 584.756 220.587 589.857 234.021 601.6C255.426 621.297 266.344 643.606 268.035 669.57C269.239 687.085 266.567 704.962 261.352 720.636C257.358 732.681 252.313 743.827 246.278 753.939C242.925 756.036 239.74 758.451 236.563 760.788C232.587 764.12 228.131 766.695 223.281 768.464C208.576 773.508 195.642 782.181 185.043 794.104C180.184 799.818 173.775 803.362 166.371 804.431C161.556 805.166 156.336 804.644 150.982 802.894C143.576 800.385 136.129 796.655 128.851 791.81C114.317 782.291 100.142 771.667 86.4113 761.513C73.2371 754.453 60.3525 744.998 48.2708 733.523C43.6548 729.411 39.0082 725.24 35.1324 721.629C15.6586 710.334 -4.88156 695.322 -23.8474 672.951C-26.6133 669.668 -29.3281 666.231 -31.965 662.643C-56.91 628.161 -73.6371 580.797 -57.7932 546.454C-48.226 525.629 -31.2038 519.666 -9.46341 524.697C0.915843 527.088 11.7471 532.613 22.6477 537.697C30.8725 541.585 39.0714 546.407 47.276 550.601C55.7407 554.918 63.3854 556.254 70.1837 553.374C84.7089 547.282 101.738 551.377 119.814 561.369Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M123.796 548.58C130.247 552.465 136.752 556.451 143.164 560.835C161.325 573.539 180.403 578.739 196.843 575.467C199.831 574.92 202.869 574.542 205.958 574.336C221.801 573.525 238.266 578.998 253.847 592.377C278.294 614.319 291.087 639.787 293.329 669.738C294.916 689.984 292.004 710.746 286.092 728.952C281.534 742.897 275.62 755.684 268.439 767.121C264.435 769.388 260.621 772.035 256.831 774.615C252.027 778.232 246.698 780.98 240.942 782.807C223.366 787.897 209.315 797.625 196.835 812.523C191.464 819.454 184.229 823.892 175.773 825.444C170.302 826.459 164.34 826.026 158.199 824.166C149.684 821.454 141.098 817.198 132.733 811.543C116.048 800.518 99.7416 787.808 84.057 775.604C69.5637 766.955 55.3561 756.506 41.7081 744.46C36.5332 740.119 31.2666 735.789 26.6435 732.121C3.50779 719.951 -20.6533 703.16 -42.7489 677.761C-46.0239 673.984 -49.1891 670.053 -52.2314 665.984C-81.3415 626.689 -101.37 571.746 -83.1119 532.089C-71.9836 507.844 -52.1733 501.088 -26.8198 507.129C-14.6884 510.071 -2.10784 516.461 10.4907 522.475C20.103 527.029 29.6478 532.667 39.2286 537.55C49.1192 542.605 58.0161 544.033 65.8881 540.36C82.7158 532.768 102.581 537.076 123.796 548.58Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M127.769 535.779C135.037 540.035 142.357 544.411 149.537 549.3C169.958 563.465 191.377 569.026 209.672 564.914C212.97 564.263 216.344 563.755 219.834 563.465C237.411 562.257 255.837 568.101 273.557 583.102C301.008 607.308 315.696 635.905 318.481 669.849C320.41 692.772 317.281 716.464 310.681 737.216C305.632 753.126 298.911 767.622 290.639 780.447C285.968 782.855 281.523 785.736 277.098 788.514C271.477 792.404 265.288 795.318 258.641 797.203C238.317 802.384 222.349 813.469 208.672 831.009C202.772 839.138 194.709 844.466 185.203 846.513C179.066 847.824 172.345 847.48 165.4 845.501C155.79 842.627 146.072 837.884 136.623 831.454C117.798 818.899 99.3796 804.184 81.703 789.859C65.9048 779.642 50.3445 768.179 35.1463 755.561C29.3667 750.998 23.4785 746.527 18.2052 742.733C-8.59439 729.709 -36.2994 711.283 -61.6535 682.592C-65.3974 678.347 -69.0213 673.927 -72.5105 669.35C-105.778 625.256 -129.105 562.716 -108.442 517.73C-95.7637 490.09 -73.1291 482.541 -44.1894 489.586C-30.3825 492.935 -15.9525 500.471 -1.6411 507.259C9.33195 512.474 20.2476 518.951 31.195 524.53C42.5056 530.288 52.6871 531.846 61.6559 527.424C80.7895 518.202 103.475 522.898 127.769 535.779Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M131.782 523.029C139.842 527.632 147.985 532.412 155.971 537.789C179.637 553.665 201.619 559.203 222.593 554.442C226.27 553.613 229.99 553.001 233.786 552.645C253.161 551.045 273.492 557.27 293.344 573.879C323.864 600.334 340.407 632.1 343.766 670.002C346.115 695.728 342.723 722.262 335.402 745.522C329.838 763.379 322.276 779.554 312.87 793.719C307.573 796.322 302.514 799.372 297.405 802.374C290.944 806.562 283.866 809.656 276.293 811.604C253.147 816.896 235.374 829.298 220.445 849.49C214.038 858.835 205.156 865.059 194.604 867.598C187.796 869.203 180.31 868.946 172.554 866.842C161.857 863.793 151.018 858.551 140.496 851.337C119.511 837.28 99.0167 820.561 79.3461 804.133C61.8808 792.218 45.1734 779.581 28.5912 766.676C22.1974 761.896 15.7067 757.275 9.7432 753.395C-20.7283 739.501 -52.0142 719.348 -80.5424 687.524C-84.7585 682.815 -88.8414 677.906 -92.7741 672.818C-130.207 623.91 -156.826 553.786 -133.747 503.467C-119.536 472.422 -94.0868 464.084 -61.526 472.154C-46.0053 475.982 -29.8119 484.524 -13.7665 492.148C-1.42307 498.019 10.8538 505.342 23.1697 511.596C35.9159 518.087 47.3324 519.727 57.4152 514.564C78.8241 503.654 104.324 508.612 131.782 523.029Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M135.731 510.184C144.61 515.138 153.557 520.333 162.35 526.196C188.369 543.547 212.483 549.46 235.441 543.879C239.458 542.911 243.534 542.19 247.68 541.764C268.8 539.762 291.064 546.353 313.073 564.594C346.577 593.322 365.026 628.212 368.964 670.106C371.686 698.568 368.053 727.988 360.041 753.833C353.953 773.63 345.55 791.484 335.02 806.997C329.071 809.77 323.321 813.029 317.63 816.24C310.339 820.712 302.386 823.98 293.901 825.99C267.961 831.379 248.327 845.128 232.191 867.966C225.254 878.507 215.544 885.62 203.944 888.659C196.468 890.553 188.224 890.387 179.664 888.168C167.891 884.947 155.939 879.221 144.349 871.249C121.223 855.681 98.6273 836.932 76.9722 818.398C58.1543 804.878 40.1038 791.202 22.0004 777.792C15.0192 772.798 7.90676 768.038 1.31219 764.022C-32.8121 749.249 -67.6999 727.399 -99.4099 692.427C-104.099 687.259 -108.638 681.859 -113.007 676.252C-154.606 622.55 -184.525 544.828 -159.042 489.2C-143.337 454.769 -115.033 445.604 -78.8585 454.683C-61.6334 458.995 -43.6597 468.552 -25.8648 477.042C-12.1606 483.575 1.47957 491.723 15.1698 498.686C29.3362 505.882 42.0125 507.627 53.213 501.686C76.8495 489.112 105.209 494.418 135.731 510.184Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M139.712 497.398C149.401 502.708 159.163 508.293 168.742 514.653C197.132 533.489 223.438 539.819 248.313 533.36C252.68 532.25 257.11 531.419 261.599 530.927C284.482 528.514 308.668 535.495 332.826 555.353C369.411 586.306 389.696 624.375 394.178 670.242C397.283 701.435 393.419 733.736 384.69 762.05C378.079 783.787 368.835 803.319 357.179 820.18C350.579 823.123 344.224 826.639 337.855 830.015C329.509 834.54 320.907 838.515 311.519 840.282C282.841 845.757 261.299 860.857 243.94 886.333C236.49 898.086 225.959 906.098 213.31 909.635C205.164 911.826 196.156 911.746 186.783 909.4C173.924 906.016 160.844 899.791 148.179 891.027C122.894 873.939 98.2131 853.191 74.5468 832.544C54.365 817.444 34.9731 802.71 15.3754 788.799C7.89934 783.622 0.339198 778.871 -7.27348 774.566C-45.0526 758.933 -83.5231 735.375 -118.425 697.261C-123.586 691.63 -128.584 685.741 -133.396 679.622C-179.161 621.106 -212.326 535.795 -184.473 474.838C-167.18 436.993 -136.089 427.054 -96.3192 437.133C-77.3725 441.939 -57.6356 452.502 -38.1315 461.805C-23.0396 469.003 -8.04574 477.982 7.01301 485.62C22.5978 493.54 36.5148 495.4 48.7645 488.734C74.8848 474.567 106.074 480.164 139.712 497.398Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M143.684 484.597C154.234 490.172 164.731 496.351 175.134 503.109C205.894 523.43 234.266 530.076 261.191 522.855C265.907 521.601 270.675 520.652 275.508 520.074C300.161 517.264 326.271 524.616 352.549 546.126C392.157 579.313 414.334 620.55 419.369 670.404C422.837 704.337 418.753 739.515 409.338 770.336C402.214 794.008 392.139 815.215 379.367 833.418C372.126 836.526 365.131 840.28 358.118 843.841C348.897 848.595 339.472 852.829 329.166 854.628C297.656 860.219 274.308 876.656 255.716 904.775C246.846 918.55 235.644 927.049 222.689 930.656C213.887 933.152 204.132 933.182 193.963 930.744C180.017 927.186 165.811 920.463 152.069 910.919C124.643 892.32 97.8516 869.547 72.2182 846.803C50.7031 830.088 29.949 814.325 8.84166 799.885C0.735852 794.466 -7.45992 789.561 -15.7044 785.193C-57.1631 768.676 -99.2165 743.412 -137.294 702.184C-142.927 696.056 -148.391 689.659 -153.661 683.017C-203.582 619.683 -240.087 526.814 -209.789 460.509C-190.946 419.268 -157.049 408.526 -113.667 419.634C-93.0237 424.909 -71.4834 436.531 -50.2684 446.628C-33.8157 454.487 -17.4586 464.292 -1.0255 472.639C15.9871 481.277 31.1848 483.214 44.5427 475.774C72.9194 460.02 106.954 465.937 143.684 484.597Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M147.665 471.809C159.031 477.719 170.34 484.316 181.545 491.573C214.658 513.37 245.162 520.39 274.063 512.335C279.119 510.943 284.252 509.879 289.427 509.235C315.842 506.033 343.885 513.751 372.331 536.868C414.972 572.305 438.977 616.613 444.631 670.513C448.491 707.172 444.148 745.245 434.016 778.627C426.358 804.237 415.432 827.121 401.529 846.671C393.644 849.964 386.004 853.922 378.354 857.701C368.3 862.719 358.002 867.163 346.786 868.99C312.49 874.692 287.273 892.46 267.476 923.207C258 938.289 245.998 947.661 232.068 951.696C222.582 954.464 212.056 954.566 201.072 951.998C186.036 948.254 170.702 941.029 155.88 930.705C126.295 910.585 97.4188 885.812 69.8086 860.976C46.9488 842.671 24.844 825.854 2.22508 810.904C-6.5154 805.243 -15.3504 800.185 -24.228 795.758C-69.3397 778.363 -114.985 751.396 -156.245 707.021C-162.345 700.45 -168.257 693.576 -173.954 686.428C-228.042 618.3 -267.847 517.832 -235.125 446.208C-214.768 401.552 -178.036 390.012 -131.05 402.135C-108.684 407.884 -85.3954 420.484 -62.4232 431.461C-44.6097 439.981 -26.8818 450.625 -9.08016 459.648C9.35086 469.01 25.8315 471.095 40.2727 462.858C70.9554 445.473 107.825 451.714 147.665 471.809Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M151.641 459.01C163.818 465.274 175.935 472.291 187.94 480.03C223.408 503.302 256.043 510.697 286.945 501.831C292.273 500.288 297.763 499.134 303.395 498.374C331.564 494.765 361.531 502.854 392.124 527.608C437.798 565.287 463.715 612.961 469.876 670.633C474.126 710.037 469.543 750.996 458.677 786.838C450.146 815.053 438.092 840.391 423.7 859.849C415.164 863.311 406.894 867.502 398.6 871.466C387.705 876.752 376.534 881.407 364.416 883.275C327.335 889.069 300.257 908.184 279.235 941.588C269.138 957.947 256.295 968.14 241.42 972.662C231.269 975.725 219.984 975.918 208.194 973.229C193.21 969.801 176.944 962.249 159.657 950.401C127.931 928.771 96.9613 901.983 67.3132 875.03C43.1011 855.121 19.6437 837.27 -4.45419 821.848C-13.8313 815.949 -23.3044 810.732 -32.8124 806.229C-81.5884 787.979 -130.846 759.228 -175.258 711.782C-181.827 704.744 -188.198 697.382 -194.342 689.726C-252.624 616.707 -295.683 508.728 -260.565 431.779C-238.68 383.685 -199.111 371.4 -148.52 384.518C-124.441 390.766 -99.3797 404.366 -74.6811 416.166C-55.4895 425.357 -36.3694 436.903 -17.2033 446.549C2.62492 456.572 20.373 458.776 35.9264 449.818C68.9938 430.928 108.683 437.465 151.641 459.01Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M155.628 446.215C168.61 452.821 181.531 460.256 194.33 468.483C232.177 493.235 266.928 500.994 299.852 491.381C305.518 489.701 311.359 488.435 317.35 487.586C347.28 483.584 379.189 492.053 411.921 518.43C460.627 558.364 488.431 609.038 495.152 670.84C499.767 712.966 494.951 756.825 483.413 795.243C474.329 825.436 461.449 852.582 445.908 873.239C436.721 876.872 427.813 881.281 418.874 885.448C407.129 890.989 395.112 895.859 382.082 897.774C342.184 903.62 313.237 924.068 291.022 960.167C280.321 977.814 266.707 988.957 250.805 993.787C239.987 997.151 227.939 997.435 215.339 994.624C199.344 991.055 181.972 983.024 163.57 970.406C129.702 947.265 96.5976 918.429 65.0186 889.378C39.4618 867.878 14.6536 848.974 -10.9693 832.994C-20.9818 826.864 -31.0903 821.493 -41.2249 816.917C-93.6731 797.798 -146.475 767.412 -194.146 716.766C-201.188 709.266 -208.018 701.414 -214.604 693.245C-277.015 615.504 -323.403 499.85 -285.888 417.577C-262.431 366.131 -220.067 352.994 -165.872 367.127C-140.08 373.854 -113.217 388.441 -86.8246 401.116C-66.2626 410.964 -45.8576 423.303 -25.2654 433.687C-3.97855 444.485 14.9987 446.738 31.6412 437.016C67.0163 416.384 109.568 423.249 155.628 446.215Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M159.603 433.414C173.399 440.363 187.132 448.217 200.733 456.934C240.942 483.176 277.825 491.308 312.686 480.789C318.704 478.995 324.907 477.64 331.271 476.728C362.954 472.32 396.823 481.16 431.676 509.169C483.423 551.346 513.144 605.212 520.368 670.953C525.374 715.824 520.31 762.555 508.075 803.504C498.449 835.672 484.674 864.494 468.12 886.448C458.281 890.25 448.753 894.887 439.16 899.244C426.565 905.039 413.699 910.163 399.752 912.09C357.101 918.086 326.291 939.88 302.854 978.617C291.549 997.551 277.117 1009.56 260.26 1014.88C248.761 1018.51 235.945 1018.86 222.535 1015.92C205.537 1012.21 187.059 1003.7 167.456 990.263C131.43 965.602 96.2559 934.795 62.6696 903.593C35.7968 880.488 9.60942 860.546 -17.5249 844.055C-28.1783 837.681 -38.9286 832.153 -49.695 827.511C-105.796 807.512 -162.198 775.41 -212.976 721.597C-220.487 713.637 -227.767 705.291 -234.781 696.597C-301.431 613.971 -351.137 490.82 -311.169 403.195C-286.158 348.406 -241.04 334.346 -183.212 349.571C-155.723 356.767 -127.118 372.389 -98.9532 385.882C-77.0131 396.4 -55.2069 409.545 -33.2958 420.648C-10.5888 432.151 9.64841 434.509 27.3972 424.033C65.0536 401.838 110.427 408.979 159.603 433.414Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M163.584 420.627C178.191 427.92 192.734 436.187 207.136 445.386C249.722 473.127 288.723 481.605 325.575 470.279C331.915 468.317 338.458 466.829 345.178 465.823C378.641 461.012 414.369 470.238 451.418 499.86C506.189 544.272 537.743 601.263 545.578 671.035C550.959 718.622 545.672 768.249 532.714 811.725C522.536 845.872 507.923 876.434 490.279 899.63C479.79 903.603 469.616 908.444 459.386 913.019C445.941 919.068 432.229 924.408 417.37 926.381C371.924 932.473 339.263 955.608 314.579 996.94C302.66 1017.17 287.426 1030.06 269.615 1035.79C257.457 1039.73 243.888 1040.19 229.673 1037.15C211.672 1033.3 192.089 1024.29 171.305 1010.03C133.082 983.869 95.8604 951.043 60.2342 917.743C31.9881 893.063 4.46916 872.058 -24.1577 855.047C-35.4551 848.452 -46.847 842.772 -58.2443 838.054C-117.962 817.176 -177.995 783.392 -231.984 726.446C-239.97 718.022 -247.715 709.19 -255.182 699.991C-325.952 612.638 -378.897 481.821 -336.551 388.881C-310.022 330.639 -262.017 315.901 -200.614 332.063C-171.394 339.748 -141.026 356.376 -111.127 370.706C-87.8256 381.885 -64.6484 395.87 -41.3686 407.649C-17.2355 419.89 4.25371 422.339 23.0993 411.113C63.127 387.364 111.321 394.801 163.584 420.627Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M167.508 407.853C182.921 415.484 198.267 424.169 213.463 433.859C258.397 463.042 299.535 471.927 338.373 459.763C345.054 457.659 351.953 456.062 359.041 454.978C394.226 449.722 431.937 459.362 471.106 490.615C528.91 537.276 562.347 597.421 570.783 671.156C576.575 721.551 571.009 773.991 557.364 820.014C546.635 856.121 531.165 888.431 512.439 912.886C501.279 917.038 490.494 922.102 479.613 926.847C465.318 933.15 450.749 938.729 434.949 940.672C386.72 946.856 352.195 971.357 326.288 1015.33C313.765 1036.84 297.713 1050.6 278.925 1056.77C266.095 1061 251.761 1061.55 236.736 1058.39C217.733 1054.4 197.034 1044.91 175.061 1029.81C134.8 1002.1 95.3821 967.297 57.8097 931.87C28.219 905.6 -0.643003 883.555 -30.789 866.021C-42.7331 859.189 -54.7705 853.361 -66.8016 848.584C-130.238 826.842 -193.798 791.34 -250.987 731.257C-259.443 722.365 -267.646 713.045 -275.56 703.338C-350.421 611.244 -406.714 472.761 -361.981 374.502C-333.919 312.855 -283.089 297.324 -218.074 314.496C-187.139 322.66 -155 340.308 -123.358 355.47C-98.6863 367.305 -74.1553 382.121 -49.4628 394.681C-23.9094 407.626 -1.0837 410.234 18.7686 398.157C61.1137 372.753 112.2 380.577 167.508 407.853Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M171.536 395.039C187.766 403.017 203.927 412.125 219.927 422.311C267.223 452.984 310.409 462.176 351.297 449.249C358.319 447.005 365.571 445.294 373.023 444.121C410.001 438.512 449.613 448.479 490.93 481.369C551.766 530.279 587.078 593.582 596.022 671.29C602.173 724.373 596.376 779.714 582.049 828.297C570.768 866.383 554.433 900.428 534.626 926.14C522.813 930.39 511.402 935.74 499.837 940.709C484.702 947.261 469.284 953.075 452.597 955.107C401.581 961.402 365.191 987.288 338.066 1033.84C324.929 1056.64 308.123 1071.36 288.312 1077.9C274.819 1082.42 259.734 1083.06 243.91 1079.79C223.886 1075.66 202.084 1065.68 178.928 1049.75C136.461 1020.54 95.0056 983.713 55.4412 946.165C24.4983 918.29 -5.69701 895.202 -37.3735 877.167C-49.9378 870.097 -62.594 864.1 -75.2341 859.227C-142.334 836.631 -209.477 799.423 -269.849 736.192C-278.749 726.824 -287.382 717.007 -295.713 706.784C-374.785 609.789 -434.351 463.804 -387.175 360.217C-357.542 295.237 -303.934 278.826 -235.307 297.027C-202.676 305.66 -168.756 324.325 -135.399 340.333C-109.319 352.805 -83.4553 368.48 -57.4249 381.659C-30.4379 395.356 -6.43474 397.982 14.5891 385.228C59.1255 358.16 113.07 366.352 171.536 395.039Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M175.509 382.239C192.542 390.569 209.501 400.102 226.292 410.783C275.95 442.926 321.353 452.559 364.148 438.759C371.506 436.373 379.108 434.549 386.923 433.294C425.654 427.278 467.207 437.626 510.663 472.138C574.532 523.297 611.72 589.739 621.234 671.444C627.751 727.248 621.736 785.518 606.691 836.59C594.857 876.655 577.675 912.445 556.778 939.4C544.344 943.878 532.265 949.388 520.102 954.536C504.108 961.347 487.835 967.381 470.245 969.455C416.435 975.846 378.198 1003.02 349.845 1052.26C336.102 1076.37 318.44 1091.89 297.694 1098.9C283.537 1103.73 267.688 1104.46 251.053 1101.06C230.027 1096.79 207.108 1086.33 182.781 1069.57C138.163 1038.86 94.6067 999.999 53.0579 960.343C20.7704 930.878 -10.7946 906.747 -43.9636 888.192C-57.1692 880.891 -70.4641 874.742 -83.731 869.8C-154.485 846.324 -225.228 807.399 -288.774 741.035C-298.18 731.23 -307.305 720.94 -316.107 710.212C-399.345 608.404 -462.212 454.84 -412.641 345.929C-381.496 277.481 -325.073 260.282 -252.802 279.55C-218.449 288.677 -182.785 308.357 -147.675 325.193C-120.255 338.355 -93.0164 354.832 -65.6099 368.7C-37.2102 383.087 -11.9319 385.848 10.1907 372.324C57.1841 343.658 113.951 352.127 175.509 382.239Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M179.501 369.446C197.347 378.116 215.119 388.061 232.714 399.223C284.743 432.851 332.27 442.844 377.049 428.224C384.744 425.697 392.699 423.765 400.879 422.435C441.381 416.021 484.851 426.726 530.463 462.87C597.354 516.282 636.427 585.878 646.486 671.556C653.393 730.105 647.138 791.261 631.379 844.856C619.003 886.894 600.974 924.428 578.987 952.627C565.902 957.275 553.192 963.018 540.377 968.355C523.55 975.429 506.421 981.684 487.912 983.79C431.318 990.272 391.211 1018.79 361.641 1070.69C347.287 1096.08 328.823 1112.48 307.101 1119.93C292.271 1125.03 275.663 1125.85 258.223 1122.34C236.185 1117.92 212.178 1107 186.661 1089.39C139.894 1057.16 94.2908 1016.28 50.6928 974.528C17.0703 943.468 -15.855 918.288 -50.5258 899.218C-64.3595 891.684 -78.2803 885.376 -92.1618 880.353C-166.587 856.009 -240.935 815.314 -307.641 745.865C-317.504 735.585 -327.075 724.799 -336.311 713.558C-423.706 606.951 -489.866 445.804 -437.909 331.501C-405.225 259.663 -345.892 241.718 -270.11 261.939C-234.043 271.545 -196.619 292.159 -159.732 309.957C-130.951 323.78 -102.315 341.101 -73.5282 355.713C-43.7179 370.809 -17.1701 373.67 6.04923 359.397C55.2206 329.111 114.823 337.904 179.501 369.446Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M183.488 356.673C202.148 365.687 220.733 376.05 239.131 387.702C293.52 422.82 343.18 433.187 389.942 417.747C397.971 415.073 406.274 413.023 414.814 411.606C457.086 404.795 502.478 415.89 550.221 453.661C620.146 509.302 661.065 582.072 671.712 671.737C678.994 733.002 672.507 797.06 656.044 853.171C643.123 897.203 624.231 936.453 601.163 965.909C587.426 970.727 574.079 976.688 560.627 982.225C542.928 989.51 524.965 996.047 505.546 998.18C446.165 1004.77 404.198 1034.62 373.406 1089.16C358.428 1115.84 339.174 1133.11 316.476 1140.99C300.979 1146.39 283.609 1147.3 265.359 1143.67C242.288 1139.14 217.206 1127.7 190.497 1109.28C141.612 1075.57 93.8279 1032.65 48.3027 988.763C13.3261 956.118 -20.9402 929.88 -57.1226 910.3C-71.6077 902.543 -86.1756 896.082 -100.692 890.977C-178.773 865.773 -256.682 823.434 -326.601 750.78C-336.939 740.041 -346.97 728.766 -356.646 717.009C-448.207 605.588 -517.659 436.857 -463.255 317.278C-429.046 242.021 -366.943 223.292 -287.503 244.518C-249.722 254.603 -210.498 276.314 -171.928 294.829C-141.777 309.308 -111.787 327.46 -81.6647 342.708C-50.4169 358.518 -22.6188 361.49 1.69735 346.467C53.2456 314.569 115.647 323.687 183.488 356.673Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M187.445 343.842C206.919 353.205 226.315 363.994 245.515 376.142C302.26 412.716 354.042 423.462 402.797 407.197C411.167 404.382 419.826 402.222 428.734 400.727C472.751 393.515 520.084 404.99 569.985 444.374C642.942 502.264 685.765 578.177 696.947 671.82C704.61 735.836 697.884 802.76 680.716 861.408C667.253 907.414 647.525 948.476 623.345 979.112C608.984 984.105 594.99 990.29 580.883 996.035C562.35 1003.6 543.508 1010.32 523.187 1012.49C461.022 1019.18 417.193 1050.37 385.193 1107.57C369.668 1135.51 349.537 1153.7 325.903 1162.07C309.726 1167.75 291.588 1168.73 272.525 1164.96C248.481 1160.28 222.254 1148.38 194.366 1129.1C143.307 1093.85 93.4698 1048.97 45.9507 1002.98C9.62184 968.727 -26.0046 941.442 -63.6505 921.419C-78.7904 913.395 -94.012 906.746 -109.172 901.536C-190.924 875.463 -272.416 831.417 -345.517 755.616C-356.323 744.41 -366.81 732.645 -376.927 720.375C-472.655 604.161 -545.407 427.831 -488.567 302.982C-452.808 224.328 -387.891 204.811 -304.836 227.047C-265.351 237.616 -224.354 260.328 -184.06 279.685C-152.577 294.748 -121.186 313.798 -89.679 329.751C-57.0128 346.284 -27.9549 349.361 -2.56678 333.565C51.2798 300.022 116.564 309.439 187.445 343.842Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M191.389 331.076C211.67 340.78 231.873 351.984 251.87 364.62C311.004 402.668 364.892 413.783 415.631 396.698C424.342 393.751 433.353 391.484 442.624 389.905C488.404 382.281 537.66 394.127 589.709 435.148C665.698 495.286 710.397 574.338 722.132 671.969C730.177 738.716 723.219 808.54 705.346 869.725C691.332 917.69 670.726 960.439 645.487 992.376C630.456 997.549 615.858 1003.97 601.101 1009.89C581.717 1017.71 562.027 1024.66 540.787 1026.86C475.828 1033.64 430.146 1066.2 396.907 1126.01C380.73 1155.26 359.838 1174.3 335.198 1183.03C318.364 1189.02 299.471 1190.11 279.604 1186.23C254.559 1181.4 227.241 1169.05 198.156 1148.92C144.948 1112.14 93.0083 1065.25 43.5048 1017.15C5.84682 981.34 -31.1475 952.991 -70.3336 932.382C-86.1017 924.151 -101.947 917.379 -117.715 912.132C-203.081 885.141 -288.185 839.403 -364.46 760.469C-375.733 748.795 -386.675 736.54 -397.235 723.76C-497.128 602.731 -573.173 418.818 -513.957 288.582C-476.658 206.538 -408.914 186.209 -322.256 209.459C-281.057 220.507 -238.289 244.24 -196.261 264.434C-163.371 280.244 -130.655 300.047 -97.7582 316.739C-63.6642 333.99 -33.3465 337.173 -6.84249 320.617C49.3159 285.477 117.445 295.214 191.389 331.076Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M195.399 318.254C216.505 328.319 237.531 339.961 258.341 353.107C319.817 392.656 375.856 404.135 428.578 386.23C437.63 383.148 446.996 380.777 456.635 379.128C504.206 371.169 555.373 383.337 609.561 425.98C688.568 488.339 735.156 570.578 747.463 672.187C755.881 741.669 748.675 814.364 730.121 878.11C715.516 927.982 694.056 972.553 667.774 1005.73C652.091 1011.07 636.848 1017.7 621.481 1023.82C601.248 1031.89 580.719 1039.07 558.56 1041.31C490.824 1048.19 443.273 1082.08 408.818 1144.55C392.029 1175.09 370.335 1195 344.711 1204.16C327.207 1210.44 307.554 1211.61 286.88 1207.62C260.823 1202.64 232.374 1189.7 202.142 1168.85C146.776 1130.55 92.7409 1081.66 41.2455 1031.45C2.21992 994.001 -36.0924 964.638 -76.7899 943.519C-93.1996 935.059 -109.683 928.129 -126.077 922.796C-215.155 894.976 -303.812 847.516 -383.289 765.444C-395.037 753.311 -406.438 740.567 -417.438 727.27C-521.487 601.424 -600.824 409.927 -539.221 274.372C-500.489 188.827 -429.909 167.68 -339.594 191.954C-296.691 203.486 -252.142 228.235 -208.38 249.265C-174.101 265.723 -140.031 286.356 -105.8 303.704C-70.2957 321.665 -38.7086 324.948 -11.1156 307.628C47.3509 270.93 118.325 280.986 195.399 318.254Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M199.384 305.468C221.221 316.061 243.23 327.707 264.735 341.491C328.574 382.51 386.746 394.363 441.421 375.58C450.803 372.345 460.514 369.852 470.51 368.112C519.822 359.698 572.974 372.352 629.272 416.56C711.326 481.196 759.76 566.517 772.65 672.171C781.45 744.384 774.013 819.98 754.739 886.214C739.643 938.181 717.312 984.361 689.902 1018.8C673.578 1024.31 657.687 1031.16 641.647 1037.49C620.554 1045.83 599.179 1053.22 576.118 1055.5C505.557 1062.43 456.185 1097.71 420.488 1162.82C403.084 1194.66 380.581 1215.45 353.991 1225.04C335.821 1231.61 315.409 1232.88 293.928 1228.78C266.888 1223.65 237.316 1210.3 205.883 1188.55C148.375 1148.74 92.2397 1097.82 38.7598 1045.5C-1.61028 1006.46 -41.2827 976.048 -83.4822 954.414C-100.531 945.724 -117.65 938.64 -134.666 933.234C-227.407 904.539 -319.657 855.377 -402.316 770.157C-414.533 757.559 -426.39 744.325 -437.831 730.515C-546.047 599.874 -628.693 400.783 -564.648 259.899C-524.305 170.982 -450.891 149.129 -357.025 174.409C-312.414 186.386 -266.071 212.198 -220.59 234.037C-184.969 251.165 -149.528 272.639 -113.9 290.647C-76.9599 309.341 -44.1206 312.715 -15.4306 294.646C45.3804 256.389 119.2 266.764 199.384 305.468Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M203.365 292.661C226.011 303.63 248.835 315.666 271.098 329.961C337.308 372.465 397.602 384.697 454.301 365.147C464.019 361.761 474.083 359.153 484.448 357.335C535.503 348.52 590.581 361.526 649.034 407.385C734.129 474.265 784.454 562.773 797.867 672.337C807.048 747.3 799.363 825.768 779.346 894.558C763.684 948.456 740.509 996.433 712.027 1032.12C695.052 1037.8 678.533 1044.86 661.839 1051.39C639.923 1059.98 617.674 1067.61 593.709 1069.95C520.393 1077.04 469.12 1113.6 432.236 1181.35C414.258 1214.55 390.888 1236.16 363.372 1246.13C344.534 1252.99 323.364 1254.35 301.079 1250.14C273.008 1244.87 242.311 1231.06 209.736 1208.44C150.078 1167.12 91.857 1114.21 36.3851 1059.76C-5.29719 1019.08 -46.3393 987.624 -90.1096 965.437C-107.794 956.519 -125.545 949.279 -143.182 943.793C-239.595 914.23 -335.473 863.366 -421.268 774.994C-433.957 761.933 -446.273 748.209 -458.157 733.887C-570.5 598.505 -656.46 391.768 -590.026 245.546C-548.112 153.297 -471.912 130.577 -374.433 156.867C-328.102 169.357 -280.009 196.127 -232.789 218.837C-195.803 236.587 -158.985 258.935 -121.977 277.686C-83.6264 297.089 -49.5272 300.569 -19.7309 281.745C43.4147 241.842 120.079 252.537 203.365 292.661Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M207.344 279.875C230.801 291.18 254.429 303.63 277.498 318.412C346.069 362.406 408.488 374.997 467.17 354.627C477.23 351.111 487.643 348.391 498.366 346.478C551.192 337.265 608.169 350.618 668.794 398.138C756.914 467.252 809.105 558.904 823.096 672.48C832.66 750.174 824.744 831.523 804.052 902.816C787.848 958.688 763.818 1008.4 734.253 1045.34C716.627 1051.19 699.47 1058.47 682.131 1065.2C659.365 1074.05 636.306 1081.89 611.369 1084.25C535.277 1091.43 482.181 1129.32 444.026 1199.73C425.405 1234.15 401.315 1256.73 372.751 1267.13C353.235 1274.31 331.286 1275.76 308.173 1271.42C279.108 1266.02 247.335 1251.69 213.512 1228.28C151.712 1185.45 91.3811 1130.51 33.9152 1073.96C-9.1421 1031.73 -51.5176 999.228 -96.7401 976.574C-115.07 967.424 -133.462 960.028 -151.727 954.462C-251.785 924.034 -351.24 871.386 -440.252 779.958C-453.414 766.434 -466.188 752.221 -478.517 737.387C-595.056 597.134 -684.277 382.87 -615.428 231.314C-571.973 135.674 -492.956 112.147 -391.873 139.451C-343.821 152.435 -293.965 180.211 -245.01 203.758C-206.64 222.213 -168.498 245.314 -130.131 264.746C-90.3439 284.882 -54.9753 288.462 -24.0976 268.86C41.4477 227.296 120.948 238.316 207.344 279.875Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M756.436 1058.59C714.15 1072.51 674.464 1094.41 628.971 1098.53C550.086 1105.81 495.1 1145.06 455.767 1218.1C406.976 1308.67 321.557 1320.25 217.394 1248.01C153.436 1203.67 91.0192 1146.72 31.5613 1088.05C-30.3494 1026.96 -90.9506 985.819 -160.185 964.94C-279.049 929.073 -396.953 863.016 -498.757 740.662C-619.462 595.596 -711.978 373.768 -640.722 216.883C-595.726 117.833 -513.894 93.5177 -409.205 121.817C-359.486 135.304 -307.819 164.063 -257.083 188.421C-217.335 207.547 -177.815 231.522 -138.088 251.615C-96.8806 272.455 -60.2617 276.146 -28.3025 255.765C39.4802 212.624 121.817 223.949 211.314 266.948C235.581 278.609 260.062 291.522 283.934 306.829C354.868 352.293 419.419 365.258 480.077 344.054C543.501 321.902 613.257 329.845 688.591 388.837C779.743 460.2 833.826 555.02 848.354 672.554C866.072 816.06 826.232 971.697 756.436 1058.59Z"
                    stroke="#22B573" strokeMiterlimit="10"/>
                <path
                    d="M135.14 647.826C141.39 656.284 142.921 662.831 141.826 668.898C141.044 672.764 139.743 676.253 137.965 679.254C135.968 682.515 135.032 686.821 135.272 691.648C135.781 699.914 132.924 703.168 126.143 701.953C123.928 701.419 121.676 700.541 119.443 699.34C118.018 698.596 116.616 697.635 115.274 696.483C112.528 694.164 109.611 692.442 106.703 691.42C103.794 690.398 100.954 690.098 98.3577 690.539C91.6631 691.353 85.7964 687.099 81.2631 678.342C79.5745 675.29 78.3373 672.03 77.6367 668.786C76.4677 661.852 73.8374 655.377 70.7671 648.69C68.1554 643.289 66.5983 637.582 66.2657 632.191C65.933 626.8 66.8376 621.931 68.8808 618.117C70.1193 615.365 72.1669 613.468 74.7606 612.669C77.3544 611.869 80.3758 612.205 83.4371 613.631C89.1674 615.869 94.8385 619.919 99.838 625.344C106.87 633.178 114.401 637.013 122.266 639.448C127.51 640.96 132.425 644.22 135.14 647.826Z"
                    stroke="#22B573" strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M115.84 574.167C120.669 577.359 125.547 580.526 130.358 583.911C144.019 593.685 158.422 598.133 171.131 596.502C173.428 596.255 175.771 596.095 178.091 596.075C190.409 596.068 203.018 600.783 214.312 610.903C232.702 628.36 241.726 647.521 242.865 669.497C243.686 684.282 241.255 699.272 236.736 712.415C233.26 722.512 229.057 731.982 224.161 740.746C221.459 742.673 218.912 744.869 216.411 747.059C213.266 750.102 209.685 752.504 205.745 754.214C193.714 759.206 182.838 766.446 173.378 775.76C169.035 780.257 163.451 782.91 157.096 783.494C152.966 783.931 148.523 783.316 143.992 781.681C137.684 779.359 131.359 776.123 125.162 772.046C112.767 764.057 100.666 755.365 88.9772 747.38C77.1519 741.929 65.557 733.448 55.0613 722.574C51.0882 718.665 47.0634 714.635 43.8205 711.14C28.0257 700.73 11.0603 687.411 -4.73735 668.139C-7.05081 665.311 -9.30171 662.365 -11.4899 659.3C-32.3006 629.575 -45.7057 589.853 -32.287 560.848C-24.2314 543.398 -10.0159 538.33 8.08248 542.274C16.7498 544.168 25.8559 548.666 34.9747 552.938C41.8387 556.166 48.6572 560.153 55.4953 563.651C62.3334 567.15 68.9344 568.49 74.6264 566.364C86.6889 561.836 100.827 565.524 115.84 574.167Z"
                    stroke="#22B573" strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M131.782 523.029C139.842 527.632 147.985 532.412 155.971 537.789C179.637 553.665 201.619 559.203 222.593 554.442C226.27 553.613 229.99 553.001 233.786 552.645C253.161 551.045 273.492 557.27 293.344 573.879C323.864 600.334 340.407 632.1 343.766 670.002C346.115 695.728 342.723 722.262 335.402 745.522C329.838 763.379 322.276 779.554 312.87 793.719C307.573 796.322 302.514 799.372 297.405 802.374C290.944 806.562 283.866 809.656 276.293 811.604C253.147 816.896 235.374 829.298 220.445 849.49C214.038 858.835 205.156 865.059 194.604 867.598C187.796 869.203 180.31 868.946 172.554 866.842C161.857 863.793 151.018 858.551 140.496 851.337C119.511 837.28 99.0167 820.561 79.3461 804.133C61.8808 792.218 45.1734 779.581 28.5912 766.676C22.1974 761.896 15.7067 757.275 9.7432 753.395C-20.7283 739.501 -52.0142 719.348 -80.5424 687.524C-84.7585 682.815 -88.8414 677.906 -92.7741 672.818C-130.207 623.91 -156.826 553.786 -133.747 503.467C-119.536 472.422 -94.0868 464.084 -61.526 472.154C-46.0053 475.982 -29.8119 484.524 -13.7665 492.148C-1.42307 498.019 10.8538 505.342 23.1697 511.596C35.9159 518.087 47.3324 519.727 57.4152 514.564C78.8241 503.654 104.324 508.612 131.782 523.029Z"
                    stroke="#22B573" strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M147.665 471.809C159.031 477.719 170.34 484.316 181.545 491.573C214.658 513.37 245.162 520.39 274.063 512.335C279.119 510.943 284.252 509.879 289.427 509.235C315.842 506.033 343.885 513.751 372.331 536.868C414.972 572.305 438.977 616.613 444.631 670.513C448.491 707.172 444.148 745.245 434.016 778.627C426.358 804.237 415.432 827.121 401.529 846.671C393.644 849.964 386.004 853.922 378.354 857.701C368.3 862.719 358.002 867.163 346.786 868.99C312.49 874.692 287.273 892.46 267.476 923.207C258 938.289 245.998 947.661 232.068 951.696C222.582 954.464 212.056 954.566 201.072 951.998C186.036 948.254 170.702 941.029 155.88 930.705C126.295 910.585 97.4188 885.812 69.8086 860.976C46.9488 842.671 24.844 825.854 2.22508 810.904C-6.5154 805.243 -15.3504 800.185 -24.228 795.758C-69.3397 778.363 -114.985 751.396 -156.245 707.021C-162.345 700.45 -168.257 693.576 -173.954 686.428C-228.042 618.3 -267.847 517.832 -235.125 446.208C-214.768 401.552 -178.036 390.012 -131.05 402.135C-108.684 407.884 -85.3954 420.484 -62.4232 431.461C-44.6097 439.981 -26.8818 450.625 -9.08016 459.648C9.35086 469.01 25.8315 471.095 40.2727 462.858C70.9554 445.473 107.825 451.714 147.665 471.809Z"
                    stroke="#22B573" strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M163.584 420.627C178.191 427.92 192.734 436.187 207.136 445.386C249.722 473.127 288.723 481.605 325.575 470.279C331.915 468.317 338.458 466.829 345.178 465.823C378.641 461.012 414.369 470.238 451.418 499.86C506.189 544.272 537.743 601.263 545.578 671.035C550.959 718.622 545.672 768.249 532.714 811.725C522.536 845.872 507.923 876.434 490.279 899.63C479.79 903.603 469.616 908.444 459.386 913.019C445.941 919.068 432.229 924.408 417.37 926.381C371.924 932.473 339.263 955.608 314.579 996.94C302.66 1017.17 287.426 1030.06 269.615 1035.79C257.457 1039.73 243.888 1040.19 229.673 1037.15C211.672 1033.3 192.089 1024.29 171.305 1010.03C133.082 983.869 95.8604 951.043 60.2342 917.743C31.9881 893.063 4.46916 872.058 -24.1577 855.047C-35.4551 848.452 -46.847 842.772 -58.2443 838.054C-117.962 817.176 -177.995 783.392 -231.984 726.446C-239.97 718.022 -247.715 709.19 -255.182 699.991C-325.952 612.638 -378.897 481.821 -336.551 388.881C-310.022 330.639 -262.017 315.901 -200.614 332.063C-171.394 339.748 -141.026 356.376 -111.127 370.706C-87.8256 381.885 -64.6484 395.87 -41.3686 407.649C-17.2355 419.89 4.25371 422.339 23.0993 411.113C63.127 387.364 111.321 394.801 163.584 420.627Z"
                    stroke="#22B573" strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M179.501 369.446C197.347 378.116 215.119 388.061 232.714 399.223C284.743 432.851 332.27 442.844 377.049 428.224C384.744 425.697 392.699 423.765 400.879 422.435C441.381 416.021 484.851 426.726 530.463 462.87C597.354 516.282 636.427 585.878 646.486 671.556C653.393 730.105 647.138 791.261 631.379 844.856C619.003 886.894 600.974 924.428 578.987 952.627C565.902 957.275 553.192 963.018 540.377 968.355C523.55 975.429 506.421 981.684 487.912 983.79C431.318 990.272 391.211 1018.79 361.641 1070.69C347.287 1096.08 328.823 1112.48 307.101 1119.93C292.271 1125.03 275.663 1125.85 258.223 1122.34C236.185 1117.92 212.178 1107 186.661 1089.39C139.894 1057.16 94.2908 1016.28 50.6928 974.528C17.0703 943.468 -15.855 918.288 -50.5258 899.218C-64.3595 891.684 -78.2803 885.376 -92.1618 880.353C-166.587 856.009 -240.935 815.314 -307.641 745.865C-317.504 735.585 -327.075 724.799 -336.311 713.558C-423.706 606.951 -489.866 445.804 -437.909 331.501C-405.225 259.663 -345.892 241.718 -270.11 261.939C-234.043 271.545 -196.619 292.159 -159.732 309.957C-130.951 323.78 -102.315 341.101 -73.5282 355.713C-43.7179 370.809 -17.1701 373.67 6.04923 359.397C55.2206 329.111 114.823 337.904 179.501 369.446Z"
                    stroke="#22B573" strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M195.399 318.254C216.505 328.319 237.531 339.961 258.341 353.107C319.817 392.656 375.856 404.135 428.578 386.23C437.63 383.148 446.996 380.777 456.635 379.128C504.206 371.169 555.373 383.337 609.561 425.98C688.568 488.339 735.156 570.578 747.463 672.187C755.881 741.669 748.675 814.364 730.121 878.11C715.516 927.982 694.056 972.553 667.774 1005.73C652.091 1011.07 636.848 1017.7 621.481 1023.82C601.248 1031.89 580.719 1039.07 558.56 1041.31C490.824 1048.19 443.273 1082.08 408.818 1144.55C392.029 1175.09 370.335 1195 344.711 1204.16C327.207 1210.44 307.554 1211.61 286.88 1207.62C260.823 1202.64 232.374 1189.7 202.142 1168.85C146.776 1130.55 92.7409 1081.66 41.2455 1031.45C2.21992 994.001 -36.0924 964.638 -76.7899 943.519C-93.1996 935.059 -109.683 928.129 -126.077 922.796C-215.155 894.976 -303.812 847.516 -383.289 765.444C-395.037 753.311 -406.438 740.567 -417.438 727.27C-521.487 601.424 -600.824 409.927 -539.221 274.372C-500.489 188.827 -429.909 167.68 -339.594 191.954C-296.691 203.486 -252.142 228.235 -208.38 249.265C-174.101 265.723 -140.031 286.356 -105.8 303.704C-70.2957 321.665 -38.7086 324.948 -11.1156 307.628C47.3509 270.93 118.325 280.986 195.399 318.254Z"
                    stroke="#22B573" strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M756.436 1058.59C714.15 1072.51 674.464 1094.41 628.971 1098.53C550.086 1105.81 495.1 1145.06 455.767 1218.1C406.976 1308.67 321.557 1320.25 217.394 1248.01C153.436 1203.67 91.0192 1146.72 31.5613 1088.05C-30.3494 1026.96 -90.9506 985.819 -160.185 964.94C-279.049 929.073 -396.953 863.016 -498.757 740.662C-619.462 595.596 -711.978 373.768 -640.722 216.883C-595.726 117.833 -513.894 93.5177 -409.205 121.817C-359.486 135.304 -307.819 164.063 -257.083 188.421C-217.335 207.547 -177.815 231.522 -138.088 251.615C-96.8806 272.455 -60.2617 276.146 -28.3025 255.765C39.4802 212.624 121.817 223.949 211.314 266.948C235.581 278.609 260.062 291.522 283.934 306.829C354.868 352.293 419.419 365.258 480.077 344.054C543.501 321.902 613.257 329.845 688.591 388.837C779.743 460.2 833.826 555.02 848.354 672.554C866.072 816.06 826.232 971.697 756.436 1058.59Z"
                    stroke="#22B573" strokeWidth="3" strokeMiterlimit="10"/>
            </g>
        </svg>

    );
};
