import Script from 'next/script';
import { useSettings } from '../Providers/SettingsProvider';

interface IProps {}

export const LiveChat: React.FC<IProps> = () => {
	const settings = useSettings();

	if (settings.enabled_live_chat) {
		return (
			<Script
				type="text/javascript"
				src="//cdn.rlets.com/capture_configs/06b/57b/307/2fb457185314ba61ccf3183.js"
				async={true}
			/>
		);
	}

	return null;
};
